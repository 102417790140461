import Appshell from '../../components/Appshell'
import Payment from '../../components/screens/Payment'


const text = {
  note: 'Skvelé, vybrali ste si typ predplatného:',
  title: 'jednorazový nákup',
  description: `Na vašu adresu príde balík štyroch dobrých kníh. Vaša platba bude najviac 70 eur, vrátane
  poštovného (alebo menej, v závislosti od knižného výberu v daný mesiac).`,
}

const Once = () => {
  return (
    <Appshell>
      <Payment text={text} type="once" />
    </Appshell>
  )
}

export default Once
